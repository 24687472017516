import React, { Component, Fragment } from 'react'
import LogRocket from 'logrocket'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon, PlusIcon } from '@heroicons/react/solid'
import LoadingOverlay from 'react-loading-overlay'

import { withAuthorization } from '../Session'

import SignOutButton from '../SignOut'
import Switch from 'react-switch'

import './index.css'

const INITIAL_STATE = {
  username: '',
  email: '',
  paused: false,
  sourceAccount: '',
  editing: false,
  tweetTime: 0,
  tweetTimesPro: [0],
  subscriptionLevel: 'basic',
  loading: false,
}

const times = [
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
  { value: 6, label: '6:00 AM' },
  { value: 7, label: '7:00 AM' },
  { value: 8, label: '8:00 AM' },
  { value: 9, label: '9:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '1:00 PM' },
  { value: 14, label: '2:00 PM' },
  { value: 15, label: '3:00 PM' },
  { value: 16, label: '4:00 PM' },
  { value: 17, label: '5:00 PM' },
  { value: 18, label: '6:00 PM' },
  { value: 19, label: '7:00 PM' },
  { value: 20, label: '8:00 PM' },
  { value: 21, label: '9:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
]

const getTweetTime = (hoursUTC) => {
  let currentTime = new Date()
  let offset = new Date().getTimezoneOffset() / 60

  currentTime.setHours(hoursUTC - offset)

  let currentHour = currentTime.getHours()

  return currentHour
}

const getTimeLabel = (currentHour) => {
  return times.find((hours) => hours.value === currentHour).label
}

const getHours = (time) => {
  let currentTime = new Date()
  let offset = new Date().getTimezoneOffset() / 60

  currentTime.setHours(time + offset)

  return currentTime.getHours()
}

class AccountPage extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.props.firebase.doGetUserData().then((response) => {
      let tweetTimes = []
      response.data().tweetTimes.forEach((time) => tweetTimes.push(getTweetTime(time)))

      this.setState({
        username: response.data().username,
        email: response.data().email,
        paused: response.data().paused,
        sourceAccount: response.data().sourceAccounts[0],
        tweetTime: getTweetTime(response.data().tweetTimes[0]),
        tweetTimesPro: tweetTimes,
      })

      this.props.firebase.doUpdateLastActive(new Date()).catch((error) => {
        console.log(error)
      })

      LogRocket.identify(response.data().username)
    })

    this.props.firebase.doGetUserSubscriptionLevel().then((response) => {
      if (response) {
        this.setState({ subscriptionLevel: response })
      }
    })
  }

  setTweetTime = (time) => {
    let currentTime = new Date()
    let offset = new Date().getTimezoneOffset() / 60

    currentTime.setHours(time + offset)

    let hours = currentTime.getHours()

    this.setState({ tweetTime: time })

    let times = [hours]
    this.props.firebase.doUpdateTweetTimes(times).catch((error) => {
      console.log(error)
    })
  }

  addTweetTime = (time) => {
    let tweetTimes = [...this.state.tweetTimesPro]
    tweetTimes.push(time)
    this.setState({ tweetTimesPro: tweetTimes })

    let tweetTimesMapped = tweetTimes.map((time) => getHours(time))

    this.props.firebase.doUpdateTweetTimes(tweetTimesMapped).catch((error) => {
      console.log(error)
    })
  }

  deleteTweetTime = (index) => {
    let tweetTimes = [...this.state.tweetTimesPro]
    tweetTimes.splice(index, 1)
    this.setState({ tweetTimesPro: tweetTimes })

    let tweetTimesMapped = tweetTimes.map((time) => getHours(time))

    this.props.firebase.doUpdateTweetTimes(tweetTimesMapped).catch((error) => {
      console.log(error)
    })
  }

  onToggle = (event) => {
    let paused = event
    this.setState({ paused: paused })
    this.props.firebase.doUpdatePaused(paused).catch((error) => {
      console.log(error)
    })
  }

  onAccountChange = (event) => {
    this.setState({ sourceAccount: event.target.value })
  }

  onAccountSave = () => {
    this.setState({ editing: false })
    this.props.firebase.doUpdateSourceAccounts([this.state.sourceAccount])
  }

  onAccountEdit = () => {
    this.setState({ editing: true })
  }

  setLoading = (loading) => {
    this.setState({ loading: loading })
  }

  render() {
    const {
      username,
      email,
      paused,
      sourceAccount,
      editing,
      tweetTime,
      tweetTimesPro,
      subscriptionLevel,
      loading,
    } = this.state

    return (
      <LoadingOverlay active={loading} spinner text="Redirecting...">
        <div className="">
          <div className="w-full p-6 overflow-auto">
            <div className="w-full">
              <div className="p-10 mb-3 bg-gray-800 rounded-2xl">
                <h2 className="pb-2 font-semibold">Account Details</h2>
                <div className="p-3 pl-4 mb-2 bg-gray-900 rounded-lg ">
                  <h3 className="text-sm">Username</h3>
                  <h3 className="text-lg font-bold text-gray-300">{username}</h3>
                </div>
                <div className="p-3 pl-4 mb-6 bg-gray-900 rounded-lg ">
                  <h3 className="text-sm">Email</h3>
                  <h3 className="text-lg font-bold text-gray-300">{email}</h3>
                </div>
                <SignOutButton />
              </div>

              <div className="p-10 mb-3 bg-gray-800 rounded-2xl">
                <h2 className="pb-2 font-semibold">Subscription</h2>
                <div className="flex justify-between p-3 pl-4 mb-2 bg-gray-900 rounded-lg">
                  <div>
                    <h3 className="text-sm">Plan</h3>
                    <h3 className="text-lg font-bold text-gray-300">
                      {subscriptionLevel === 'pro' ? 'Pro' : 'Basic'}
                    </h3>
                  </div>
                  <div>
                    {subscriptionLevel === 'basic' ? (
                      <button
                        className="p-3 px-6 ml-2 text-white rounded-lg"
                        style={{
                          background:
                            'linear-gradient(45deg, rgba(173,71,214,1) 0%, rgba(241,163,131,1) 100%)',
                        }}
                        onClick={() => {
                          LogRocket.track('Clicked Upgrade')
                          this.props.firebase.doStartCheckoutSession()
                          this.setLoading(true)
                        }}
                      >
                        Upgrade to Pro
                      </button>
                    ) : (
                      <button
                        className="p-3 px-6 text-white transition duration-300 ease-in-out bg-gray-700 rounded-lg hover:bg-gray-600"
                        onClick={() => {
                          this.props.firebase.doOpenCustomerPortal()
                          this.setLoading(true)
                        }}
                      >
                        Manage Subscription
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="p-10 mb-3 bg-gray-800 rounded-2xl">
                <h2 className="pb-2 font-semibold">Schedule</h2>
                {subscriptionLevel === 'basic' ? (
                  <div className="p-3 pl-4 mb-2 bg-gray-900 rounded-lg ">
                    <div className="flex items-center">
                      <h3 className="text-md font-extralight">
                        Automatically tweets once per day at{' '}
                      </h3>
                      <Listbox value={tweetTime} onChange={this.setTweetTime}>
                        <div className="relative w-32 mx-2">
                          <Listbox.Button className="relative w-full py-1 pl-3 pr-10 text-left bg-gray-700 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{getTimeLabel(tweetTime)}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-700 rounded-md shadow-lg max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {times.map((time, i) => {
                                return (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      `${
                                        active ? 'text-indigo-600 bg-amber-100' : 'text-gray-300'
                                      } relative py-2 pl-10 pr-4 cursor-pointer`
                                    }
                                    value={time.value}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`${
                                            selected ? 'font-medium' : 'font-normal'
                                          } block truncate`}
                                        >
                                          {time.label}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`${
                                              active ? 'text-indigo-600' : 'text-gray-300'
                                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                          >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                )
                              })}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                      <h3>{Intl.DateTimeFormat().resolvedOptions().timeZone}.</h3>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between p-3 pl-4 mb-2 bg-gray-900 rounded-lg">
                    <div>
                      <h3 className="mb-3 text-sm">
                        Tweets up to 5 times per day at these times (
                        {Intl.DateTimeFormat().resolvedOptions().timeZone})
                      </h3>
                      <div className="flex">
                        {tweetTimesPro.map((time, i) => (
                          <div
                            key={i}
                            className="flex items-center justify-center px-2 py-1 mr-2 bg-gray-700 rounded-md"
                          >
                            <span className="flex-initial max-w-full text-xs font-normal leading-none">
                              {getTimeLabel(time)}
                            </span>
                            <span className="flex flex-row-reverse flex-auto">
                              <span>
                                <svg
                                  onClick={() => this.deleteTweetTime(i)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-indigo-400"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Listbox onChange={this.addTweetTime}>
                      <div className="relative mx-2 w-28">
                        <Listbox.Button
                          className="relative py-1 pl-3 pr-10 text-left transition duration-300 ease-in-out bg-gray-900 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 hover:bg-gray-800"
                          style={{ border: '1px solid #7560F5' }}
                        >
                          <span className="block text-sm truncate" style={{ color: '#7560F5' }}>
                            Add Time
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <PlusIcon
                              style={{ color: '#7560F5' }}
                              className="w-5 h-5 "
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-700 rounded-md shadow-lg max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {times.map((time, i) => {
                              return (
                                <Listbox.Option
                                  key={i}
                                  className={({ active }) =>
                                    `${
                                      tweetTimesPro.includes(time.value)
                                        ? 'text-gray-600'
                                        : active
                                        ? 'text-indigo-600 bg-amber-100'
                                        : 'text-gray-300'
                                    } relative py-2 cursor-pointer text-center`
                                  }
                                  value={time.value}
                                  disabled={tweetTimesPro.includes(time.value)}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`${
                                          selected ? 'font-medium' : 'font-normal'
                                        } block truncate`}
                                      >
                                        {time.label}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`${
                                            active ? 'text-indigo-600' : 'text-gray-300'
                                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                        >
                                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              )
                            })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                )}
                <div className="flex pt-4">
                  <h3 className="mr-4 text-lg font-semibold text-gray-300">Pause Tweets</h3>
                  <Switch
                    checked={paused}
                    onChange={this.onToggle}
                    onColor="#7560F5"
                    offColor="#848493"
                    onHandleColor="#1C1C2C"
                    offHandleColor="#1C1C2C"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={25}
                    width={48}
                  />
                </div>
              </div>

              <div className="p-10 mb-3 bg-gray-800 rounded-2xl">
                <h2 className="pb-2 font-semibold">Tweet Source</h2>
                <h3 className="mb-4 text-md font-extralight">
                  By default, we’ll pull tweets from your own account. Only change this field if you
                  want to quote someone else’s tweets, see{' '}
                  <a className="underline" href="https://twitter.com/the_perell_bot">
                    @the_perell_bot
                  </a>{' '}
                  for an example.
                </h3>
                <div className="flex items-center justify-between p-3 pl-4 bg-gray-900 rounded-lg">
                  <div>
                    <h3 className="text-sm">Twitter handle (exclude "@")</h3>
                    {editing ? (
                      <input
                        type="text"
                        className="pl-2 mt-1 bg-gray-700 rounded-md"
                        value={sourceAccount}
                        onChange={this.onAccountChange}
                      />
                    ) : (
                      <h3 className="text-lg font-bold text-gray-300">{sourceAccount}</h3>
                    )}
                  </div>
                  {editing ? (
                    <button
                      className="px-8 py-2 mr-2 text-xs text-gray-100 transition duration-300 ease-in-out bg-gray-700 rounded-lg shadow-sm hover:bg-gray-600"
                      onClick={this.onAccountSave}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="px-8 py-2 mr-2 text-xs text-gray-100 transition duration-300 ease-in-out bg-gray-700 rounded-lg shadow-sm hover:bg-gray-600"
                      onClick={this.onAccountEdit}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    )
  }
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(AccountPage)
