import React from 'react'
import { NavLink } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'

import { AuthUserContext } from '../Session'

import './index.css'

import logo from '../../logo/logo.svg'

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (authUser ? <NavigationAuth /> : <NavigationNonAuth />)}
    </AuthUserContext.Consumer>
  </div>
)

const NavigationAuth = () => (
  <div className="p-3 bg-gray-900" style={{ height: '100vh' }}>
    <img className="p-4 mb-4" style={{ height: 60, width: 170 }} alt="logo" src={logo} />
    <NavLink activeClassName="nav-item--active" className="nav-item" to={ROUTES.QUEUE}>
      <div className="nav-item-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        <div className="nav-item-text">Queue</div>
      </div>
    </NavLink>
    <NavLink activeClassName="nav-item--active" className="nav-item" to={ROUTES.FEED}>
      <div className="nav-item-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
          />
        </svg>
        <div className="nav-item-text">Feed</div>
      </div>
    </NavLink>
    <NavLink activeClassName="nav-item--active" className="nav-item" to={ROUTES.ACCOUNT}>
      <div className="nav-item-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
        <div className="nav-item-text">Account</div>
      </div>
    </NavLink>
    {/*<button
      className="p-2 px-6 m-4 mt-6 text-white rounded-full shadow-lg"
      style={{
        background: 'linear-gradient(45deg, rgba(173,71,214,1) 0%, rgba(241,163,131,1) 100%)',
      }}
      onClick={() => this.props.firebase.doStartCheckoutSession()}
    >
      Upgrade to Pro
    </button>*/}
  </div>
)

const NavigationNonAuth = () => <div></div>

export default Navigation
