import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import './index.css'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

import logo from '../../logo/logo.svg'
import { FaTwitter } from 'react-icons/fa'
import { IconContext } from 'react-icons'

import LogRocket from 'logrocket'

const SignInPage = () => (
  <div>
    <SignInTwitter />
  </div>
)

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          LogRocket.identify(socialAuthUser.additionalUserInfo.username, {
            name: socialAuthUser.additionalUserInfo.username,
            email: socialAuthUser.user.email,
          })
          this.props.firebase.doAddUserToDatabase(
            socialAuthUser.additionalUserInfo.username,
            socialAuthUser.user.email,
            socialAuthUser.credential.accessToken,
            socialAuthUser.credential.secret,
          )
        } else {
          LogRocket.identify(socialAuthUser.additionalUserInfo.username, {
            name: socialAuthUser.additionalUserInfo.username,
            email: socialAuthUser.user.email,
          })
          this.props.firebase.doUpdateUserData(
            socialAuthUser.additionalUserInfo.username,
            socialAuthUser.credential.accessToken,
            socialAuthUser.credential.secret,
          )
        }
      })
      .then(() => {
        this.setState({ error: null })
        this.props.history.push(ROUTES.HOME)
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <div className="fixed p-16 px-24 transform -translate-x-1/2 translate-y-1/2 bg-gray-800 shadow-lg bottom-1/2 left-1/2 rounded-2xl">
        <img alt="logo" className="mx-auto" style={{ height: 100, width: 200 }} src={logo} />
        <form onSubmit={this.onSubmit}>
          <button
            className="flex items-center p-4 px-10 mx-auto font-semibold transition duration-300 ease-in-out bg-gray-700 rounded-xl text-md hover:bg-indigo-500"
            style={{
              background: 'linear-gradient(45deg, rgba(173,71,214,1) 0%, rgba(78,71,220,1) 100%)',
              boxShadow: '0px 10px 40px #7A4BD140',
            }}
            type="submit"
          >
            <IconContext.Provider value={{ size: 20 }}>
              <FaTwitter className="mr-4" />
            </IconContext.Provider>
            Sign in with Twitter
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    )
  }
}

const SignInTwitter = compose(withRouter, withFirebase)(SignInTwitterBase)

export default SignInPage

export { SignInTwitter }
