import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.auth = app.auth()
    this.firestore = app.firestore()
    this.functions = app.functions()
    this.twitterProvider = new app.auth.TwitterAuthProvider()
  }

  // *** Auth API ***

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignOut = () => this.auth.signOut()

  // *** User API **

  doAddUserToDatabase = (username, email, accessToken, secret) =>
    this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid)
      .set({
        uid: this.auth.currentUser.uid,
        username: username,
        sourceAccounts: [username],
        email: email,
        accessToken: accessToken,
        secret: secret,
        deletedTweets: [],
        alreadyTweeted: [],
        queue: [],
        feed: [],
        paused: false,
        tweetTimes: [14],
      })

  doUpdateLastActive = (lastActive) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      lastActive: lastActive,
    })

  doUpdateQueue = (queue) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      queue: queue,
    })

  doUpdateFeed = (feed) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      feed: feed,
    })

  doUpdateDeletedTweets = (tweets) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      deletedTweets: tweets,
    })

  doUpdateSourceAccounts = (accounts) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      sourceAccounts: accounts,
    })

  doUpdateUserData = (username, accessToken, secret) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      username: username,
      accessToken: accessToken,
      secret: secret,
    })

  doUpdatePaused = (paused) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      paused: paused,
    })

  doUpdateTweetTimes = (times) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      tweetTimes: times,
    })

  doGetUserData = () => this.firestore.collection('users').doc(this.auth.currentUser.uid).get()

  doStartCheckoutSession = async () => {
    const docRef = await this.firestore
      .collection('customers')
      .doc(this.auth.currentUser.uid)
      .collection('checkout_sessions')
      .add({
        line_items: [
          {
            price: process.env.REACT_APP_STRIPE_PRICE_ID,
            quantity: 1,
          },
        ],
        success_url: window.location.href,
        cancel_url: window.location.href,
      })
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data()
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`)
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url)
      }
    })
  }

  doOpenCustomerPortal = async () => {
    const functionRef = this.functions.httpsCallable(
      'ext-firestore-stripe-subscriptions-createPortalLink',
    )
    const { data } = await functionRef({
      returnUrl: window.location.href,
      //locale: 'auto', // Optional, defaults to "auto"
      //configuration: 'bpc_1JSEAKHYgolSBA358VNoc2Hs', // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    })
    window.location.assign(data.url)
  }

  doGetUserSubscriptionLevel = async () => {
    await this.auth.currentUser.getIdToken(true)
    const decodedToken = await this.auth.currentUser.getIdTokenResult()
    return decodedToken.claims.stripeRole
  }
}

export default Firebase
