import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import Navigation from '../Navigation'
import SignInPage from '../SignIn'
import HomePage from '../Home'
import AccountPage from '../Account'
import FeedPage from '../Feed'

import * as ROUTES from '../../constants/routes'
import { withAuthentication, AuthUserContext } from '../Session'

const App = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (authUser ? <AppAuth /> : <AppNonAuth />)}
    </AuthUserContext.Consumer>
  </div>
)

const AppAuth = () => (
  <Router>
    <div className="flex max-w-6xl pr-16 mx-auto bg-gray-900">
      <div style={{ width: '220px', position: 'fixed' }}>
        <Navigation />
      </div>
      <div style={{ width: `calc(100% - 220px)`, marginLeft: '220px', minHeight: '100vh' }}>
        <Route path={ROUTES.QUEUE} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.FEED} component={FeedPage} />
        <Route exact path="/" render={() => <Redirect to={ROUTES.QUEUE} />} />
      </div>
    </div>
  </Router>
)

const AppNonAuth = () => (
  <Router>
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route component={SignInPage} />
  </Router>
)

export default withAuthentication(App)
