import React from 'react'

import { withFirebase } from '../Firebase'

import './index.css'

const SignOutButton = ({ firebase }) => (
  <button
    className="px-6 py-2 text-white transition duration-300 ease-in-out bg-gray-700 rounded-lg hover:bg-gray-600"
    type="button"
    onClick={firebase.doSignOut}
  >
    Sign Out
  </button>
)

export default withFirebase(SignOutButton)
