import React from 'react'

const Alert = ({ message, type }) => {
  return (
    <>
      {type === 'warning' && (
        <div
          className="p-7 px-8 mb-3 rounded-lg text-xs"
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#423D35',
            color: '#FFC559',
            border: '1px solid #272737',
            boxShadow: '0px 1px 10px #28241C',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p
            style={{
              fontSize: '15px',
              margin: 0,
              padding: 0,
              marginLeft: 10,
              border: '0px solid #000',
            }}
          >
            {message}
          </p>
        </div>
      )}
      {type === 'info' && (
        <div
          className="p-5 px-8 mb-3 rounded-lg text-xs"
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#2D324A',
            color: '#597EFF',
            border: '1px solid #2D324A',
            boxShadow: '0px 1px 10px #22253D',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p
            style={{
              fontSize: '15px',
              margin: 0,
              padding: 0,
              marginLeft: 10,
              border: '0px solid #000',
            }}
          >
            {message}
          </p>
        </div>
      )}
    </>
  )
}

export default Alert
