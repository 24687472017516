import React, { Component } from 'react'
import LogRocket from 'logrocket'

import { withAuthorization } from '../Session'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Alert from '../Shared/Alert'
import { formatDistance } from 'date-fns'

import { IconContext } from 'react-icons'
import { FaRegHeart, FaRegComment } from 'react-icons/fa'
import { FiRepeat } from 'react-icons/fi'

import './index.css'

const INITIAL_STATE = {
  queue: [],
  deletedTweets: [],
  tweetsLoaded: false,
  paused: true,
  subscriptionLevel: 'basic',
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    this.props.firebase.doGetUserData().then((response) => {
      this.setState({
        queue: response.data().queue,
        paused: response.data().paused,
        deletedTweets: response.data().deletedTweets,
        tweetsLoaded: true,
      })

      LogRocket.identify(response.data().username)
    })

    this.props.firebase.doGetUserSubscriptionLevel().then((response) => {
      if (response) {
        this.setState({ subscriptionLevel: response })
      }
    })

    this.props.firebase.doUpdateLastActive(new Date()).catch((error) => {
      console.log(error)
    })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const queue = reorder(this.state.queue, result.source.index, result.destination.index)

    this.setState({
      queue,
    })

    this.props.firebase.doUpdateQueue(queue).catch((error) => {
      console.log(error)
    })
  }

  onDelete = (removeIndex) => {
    let { queue, deletedTweets } = this.state

    let deletedTweet = queue[removeIndex].id + ''

    deletedTweets.push(deletedTweet)

    this.props.firebase.doUpdateDeletedTweets(deletedTweets).catch((error) => {
      console.log(error)
    })

    queue.splice(removeIndex, 1)

    this.setState({ queue: queue })

    this.props.firebase.doUpdateQueue(queue).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { queue, paused, tweetsLoaded, subscriptionLevel } = this.state

    return (
      <div className="w-full p-6">
        {tweetsLoaded ? (
          <div className="w-full">
            {paused && <Alert type="info" message="Queue is paused" />}
            {queue.length === 0 ? (
              <Alert type="warning" message="Queue is empty. You can add tweets from your feed." />
            ) : (
              <div className="w-full">
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {queue.map((tweet, i) => (
                          <Draggable key={tweet.id} draggableId={tweet.id} index={i}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <div className="p-6 mb-3 bg-gray-800 shadow-xl rounded-2xl">
                                  <p className="p-4 pt-2 pb-6 text-gray-300 whitespace-pre-wrap">
                                    {tweet.text}
                                  </p>
                                  <div className="flex items-baseline p-2">
                                    {subscriptionLevel === 'pro' && (
                                      <IconContext.Provider
                                        value={{ color: '#8B99A5', size: '0.9em' }}
                                      >
                                        <div className="flex items-center ml-2">
                                          <FaRegComment />
                                          <p className="pl-2 mr-4 text-xs text-gray-400">
                                            {tweet.public_metrics.reply_count}
                                          </p>
                                          <FiRepeat />
                                          <p className="pl-2 mr-4 text-xs text-gray-400">
                                            {tweet.public_metrics.retweet_count +
                                              tweet.public_metrics.quote_count}
                                          </p>
                                          <FaRegHeart />
                                          <p className="pl-2 mr-4 text-xs text-gray-400">
                                            {tweet.public_metrics.like_count}
                                          </p>
                                          {tweet.created_at && (
                                            <p className="pl-2 text-xs text-gray-400">
                                              {formatDistance(
                                                new Date(tweet.created_at),
                                                new Date(),
                                                {
                                                  addSuffix: true,
                                                },
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </IconContext.Provider>
                                    )}
                                    <div className="flex-1"></div>
                                    <button
                                      className="flex items-center px-3 py-2 text-xs transition duration-300 ease-in-out bg-gray-900 rounded-lg hover:bg-gray-700"
                                      name={i}
                                      onClick={() => this.onDelete(i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-4 h-4 mr-2"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="#557"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                      </svg>
                                      <p className="text-gray-400">Delete</p>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTheme color="#1F2937" highlightColor="#303040">
            <p>
              <Skeleton
                duration={2}
                count={3}
                height={150}
                className="mb-3"
                style={{ borderRadius: 20 }}
              />
            </p>
          </SkeletonTheme>
        )}
      </div>
    )
  }
}

const condition = (authUser) => !!authUser

const HomePage = compose(withFirebase, withAuthorization(condition))(Dashboard)

export default HomePage
